<template>
    <h3>Kam se mnou na tábor?</h3>
    <h5>Letos vám mohu nabídnout jeden tábor, kde budu jako trenér</h5>
        <p><b>Kdy?</b> Na přelomu dubna a května </p>
        <p><b>Kde?</b> na Fryšavě na úplně úžasném místě</p>
        <!--<p>S kým? Pořádá psí škola Psí škola Dancing Dogs a jako trenér tam bude Katka Lerlova a já 🙂</p>-->
        <p>Na táboře se budeme věnovat převážně <b>pozornosti a přivolání</b> a to i mezi rušivkami. Jak v běžném životě rodinného psa, tak pro sportovní psy, 
          kteří se potýkají s nepozorností na trénincích nebo zkouškách a závodech, nebo dokonce z placu odcházejí. Co ještě může tábor nabídnout? 
          Můžeme se spolu podívat i na další oblasti vašeho soužití se psím parťákem – například slušnou chůzi na prověšeném vodítku, 
          jak správně psa něco nového učit a třeba odhalíme nějaké nešvary, kvůli kterým v tréninku nepostupujete tak rychle, jak byste chtěli. 
          Pokud budete mít zájem, můžeme zapracovat například i na ošetření se souhlasem.<br>
        <a href="https://clovek-a-pes-spolu.dogres.cz/trainings/364476" target="_blank">Bližší info a přihlášku najdete zde</a></p>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'Tabory',

  setup () {
    useMeta({ title: 'Člověk a pes spolu - pořádané tábory' })
  },

}

</script>

<style lang="scss">
a {
  color: $secondary
}
</style>
